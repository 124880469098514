// User PROXY or SETTER & GETTER
// ------------------------------------------------------------------------------------------

// manage state for running acitons after logging

// Manage state for menu items
var menu = {
    sources: [],
    time: false,
    discount: false,
    handler: () => { return true }
}
const menu_handler = {
    set(obj, prop, value) {
        obj[prop] = value
        if (obj['time'] && obj['discount']) obj['handler']()
        return true
    }
};

// ---------------------------------------

var load = {
    menu: false,
    order: false,
    handler: () => { return true }
}

const load_handler = {
    set(obj, prop, value) {
        Reflect.set(...arguments);
        if (Reflect.get(obj, 'menu') && Reflect.get(obj, 'order')) {
            Reflect.get(obj, 'handler')()
        }
        return true;
    }
};

// Manage state for checkout button
var checkout_load = {
    get_orders: false,
    render_checkout: true,
    add_items: true,
    close_order: true,
    handler_load: () => { return true },
    handler_checkout: () => { return true }
}

const checkout_handler = {
    set(obj, prop, value) {
        Reflect.set(...arguments);
        if (!Reflect.get(obj, 'render_checkout') || !Reflect.get(obj, 'get_orders') || !Reflect.get(obj, 'add_items') || !Reflect.get(obj, 'close_order')) {
            Reflect.get(obj, 'handler_load')()
        } else {
            Reflect.get(obj, 'handler_checkout')()
        }
        return true;
    }
};

// EXPORT=================================================  
//
var $amazon = {
    input: '#amazon-upload-images',
    multiple: true,
    frame: 3 / 2,
    size: 1000000,
    type: '',
    urls: [],
    data: {},
    callback: {},
    files: [],
    handler: () => { return true },
    finished: () => { return true }
}

let files_handler = {
    set(obj, prop, value) {
        obj[prop] = value
        if (prop == 'files' && obj['files'].length) obj['handler']()
        return true
    }
};
window.$amazon = new Proxy($amazon, files_handler)

window.__menu = new Proxy(menu, menu_handler)
window.__venue_hours = [];
window.__load = new Proxy(load, load_handler);
window.__checkout_load = new Proxy(checkout_load, checkout_handler);
window.__all_orders_information = [];
window.dataTree = []; //remove after redesign
window.__rate_new_item = false;
window.__selectedMenuItems = [] //array to store selected menuitems for ordering
window.__dataTree = []; //hold the restuarant data
window.__isSelectOrderType = true; //to show the order type popup or not

window.__platerate_icons = {
    heart_plate: `
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1024" height="1024" viewBox="0 0 1024 1024">
            <title></title>
            <g id="icomoon-ignore"></g>
            <path fill="#bdbcbc" stroke="#4b4b4b" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4" stroke-width="180" d="M917.24 503.757c0 228.347-185.111 413.458-413.458 413.458s-413.458-185.111-413.458-413.458c0-228.347 185.111-413.458 413.458-413.458s413.458 185.111 413.458 413.458z"></path>
            <path fill="" stroke="#fff" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4" stroke-width="50" d="M917.24 503.757c0 228.347-185.111 413.458-413.458 413.458s-413.458-185.111-413.458-413.458c0-228.347 185.111-413.458 413.458-413.458s413.458 185.111 413.458 413.458z"></path>
            <path fill="#fff" d="M259.308 324.861c-67.51 67.51-67.51 176.963-0.003 244.47l244.47 244.47 244.472-244.47c67.507-67.507 67.507-176.96 0-244.47-67.51-67.507-176.963-67.507-244.47 0-67.51-67.507-176.963-67.507-244.47 0z"></path>
        </svg>
    `,
    my_heart_plate: `
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1024" height="1024" viewBox="0 0 1024 1024">
            <title></title>
            <g id="icomoon-ignore"></g>
            <path fill="#bdbcbc" stroke="#4b4b4b" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4" stroke-width="150" d="M917.24 503.757c0 228.347-185.111 413.458-413.458 413.458s-413.458-185.111-413.458-413.458c0-228.347 185.111-413.458 413.458-413.458s413.458 185.111 413.458 413.458z"></path>
            <path fill="#fff" stroke="#bdbcbc" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4" stroke-width="70" d="M917.24 503.757c0 228.347-185.111 413.458-413.458 413.458s-413.458-185.111-413.458-413.458c0-228.347 185.111-413.458 413.458-413.458s413.458 185.111 413.458 413.458z"></path>
            <path fill="" d="M259.308 324.861c-67.51 67.51-67.51 176.963-0.003 244.47l244.47 244.47 244.472-244.47c67.507-67.507 67.507-176.96 0-244.47-67.51-67.507-176.963-67.507-244.47 0-67.51-67.507-176.963-67.507-244.47 0z"></path>
        </svg>
    `,
    my_yelp_star: `
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="35" height="35" rx="6.64286" fill="#BDC6C1"/>
						<rect width="35" height="35" rx="2.21429" fill=""/>
						<path d="M17.5039 9.60335L19.6814 13.5961L19.9645 14.1152L20.5457 14.224L25.0159 15.0612L21.8915 18.3659L21.4853 18.7956L21.5614 19.3819L22.1466 23.8921L18.038 21.9418L17.5039 21.6882L16.9698 21.9418L12.8612 23.8921L13.4465 19.3819L13.5225 18.7956L13.1164 18.3659L9.99189 15.0612L14.4621 14.224L15.0433 14.1152L15.3264 13.5961L17.5039 9.60335Z" fill="white" stroke="white" stroke-width="2.49107"/>
				</svg>
		`
}
window.__user_location = {}
window.__address_location = '';

window.__ask_permissions = () => { return false }
